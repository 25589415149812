import React from 'react'
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton,
  Box,
} from '@mui/material'
// import BgImage from './images/working.png'
import PhoneIcon from '@mui/icons-material/Phone'
import MailIcon from '@mui/icons-material/Mail'
import Header from './components/Header'
// import ApartmentIcon from '@mui/icons-material/Apartment'
// import ApiIcon from '@mui/icons-material/Api'
// import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import WorkingIcon from './images/working.png'
import ComputerIcon from './images/computer.png'
import CashIcon from './images/cash.png'
import RealEstateIcon from './images/real-estate.png'
import HospitalIcon from './images/hospital.png'

const styles = {
  bodyContainer: {
    padding: 1,
    // height: '100%',
    // background: '#D93025',
    // backgroundImage: `url(${BgImage})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: '20vh',
    // backgroundPositionX: 'Right',
    // backgroundPositionY: 'bottom',
    // paddingTop: '3vh',
  },
  footerContainer: {
    height: '8vh',
    background: '#170505',
    marginTop: '1vh',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
}

function App() {
  return (
    <>
      <Paper style={styles.bodyContainer}>
        <Header />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexFlow: 'row wrap',
            borderRadius: 1,
            gap: '8px 8px',
            margin: 2,
          }}
        >
          <Card
            sx={{
              maxWidth: 480,
              flexGrow: 1,
              color: '#ffffff',
              background: '#D93025',
              textAlign: 'center',
              height: 250,
              padding: 1,
            }}
          >
            <CardContent>
              <Typography style={{ marginBottom: 2 }}>
                {/* <ApartmentIcon style={{ transform: 'scale(2.5)' }} /> */}
                <img src={WorkingIcon} alt='' style={{ height: 80 }} />
              </Typography>
              Rokyet Ventures Ltd is a leading software development company with
              over 4 years of experience in developing products and services for
              clients around Kenya. We specialize in custom software solutions,
              web application development and mobile app development.
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 480,
              flexGrow: 1,
              color: '#ffffff',
              background: '#170505',
              textAlign: 'center',
              height: 250,
              padding: 1,
            }}
          >
            <CardContent>
              <Typography style={{ marginBottom: 2 }}>
                {/* <ApiIcon style={{ transform: 'scale(2.5)' }} /> */}
                <img src={ComputerIcon} alt='' style={{ height: 50 }} />
              </Typography>
              We believe that software should be simple and reliable. We strive
              to create software that is accurate, fast and reliable while still
              being user friendly. Our team of experienced software developers
              have worked on a variety of projects, from enterprise level
              systems to mobile applications. We take pride in creating
              solutions that are tailored to the customer's needs. We are
              committed to providing the best software solutions to our
              customers.
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 480,
              flexGrow: 1,
              color: '#000000',
              background: '#ffffff',
              textAlign: 'center',
              height: 250,
              padding: 1,
            }}
          >
            <CardContent>
              <Typography style={{ marginBottom: 2 }}>
                {/* <MonetizationOnIcon style={{ transform: 'scale(2.5)' }} /> */}
                <img src={CashIcon} alt='' style={{ height: 50 }} />
              </Typography>
              At Rokyet Ventures, we understand that every business has
              different needs, so we offer highly flexible pricing that can be
              negotiated based on the size and usage of the product. We’ll work
              with you to ensure that you get the best possible solution for
              your business at a price that works for you.
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 480,
              flexGrow: 1,
              color: '#ffffff',
              background: '#1976D2',
              textAlign: 'center',
              height: 250,
              padding: 1,
            }}
          >
            <CardContent>
              <Typography style={{ marginBottom: 2 }}>
                {/* <ApartmentIcon style={{ transform: 'scale(2.5)' }} /> */}
                <img src={RealEstateIcon} alt='' style={{ height: 50 }} />
              </Typography>
              Our flagship product, AroPMIS, is a real estate management system
              that simplifies property management and provides a comprehensive
              business solution for property owners, tenants, and agents. With
              AroPMIS, you can manage your property portfolio with ease and
              efficiency.
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 480,
              flexGrow: 1,
              color: '#ffffff',
              background: '#FFA000',
              textAlign: 'center',
              height: 250,
              padding: 1,
            }}
          >
            <CardContent>
              <Typography style={{ marginBottom: 2 }}>
                {/* <LocalHospitalIcon style={{ transform: 'scale(2.5)' }} /> */}
                <img src={HospitalIcon} alt='' style={{ height: 50 }} />
              </Typography>
              AroHMIS is a hospital management information system designed to
              streamline hospital operations. We designed it to be easy to
              implement and use, helping to reduce costs and improve patient
              care. AroHMIS can be tailored to meet the specific needs of each
              healthcare organization, making it a powerful and effective tool
              for managing hospital operations.
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 480,
              flexGrow: 1,
              color: '#170505',
              background: '#FFD6D4',
              textAlign: 'center',
              height: 250,
              padding: 1,
            }}
          >
            <CardContent>
              <Typography variant='h6' component='div'>
                More Coming Soon
              </Typography>
              <Divider variant='middle' />
              <Typography
                sx={{ fontSize: 14 }}
                // color='text.secondary'
                gutterBottom
              >
                We're working towards making our new website fully available
                soon. Until then, you may email or telephone us to find out more
                about our products and solutions.
              </Typography>
              <IconButton>
                <MailIcon color='primary' /> info@rokyetventures.com
              </IconButton>
              <IconButton>
                <PhoneIcon color='primary' /> +254 (0) 729 174 776
              </IconButton>
            </CardContent>
          </Card>
        </Box>
      </Paper>
      <Paper style={styles.footerContainer}>
        <p
          style={{
            textAlign: 'center',
            margin: 'auto',
            width: '50%',
            color: '#ffffff',
            fontSize: '1',
            fontFamily: 'Tahoma, Geneva, Verdana, sans-serif',
          }}
        >
          Copyright © 2023,
          <br /> Rokyet Ventures Ltd-Kenya.
        </p>
      </Paper>
    </>
  )
}

export default App
