import React from 'react'
import Logo from '../images/Rokyet-ventures-no-bg.svg'

function Header() {
  return (
    <div
      style={{
        background: '#ffffff',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexFlow: 'row wrap',
        padding: 10,
      }}
    >
      <img
        src={Logo}
        alt=''
        style={{
          float: 'right',
          filter:
            'invert(11%) sepia(98%) saturate(3748%) hue-rotate(357deg) brightness(99%) contrast(120%)',
          height: '3rem',
          marginLeft: 10,
        }}
      />
      <table>
        <tr>
          <font size='6'>Rokyet Ventures</font>
        </tr>
        <tr>
          <font>Custom Solutions. Made Simple. Made Right</font>
        </tr>
      </table>
    </div>
  )
}

export default Header
